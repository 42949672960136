<template>
  <base-layout
    show-back-btn="true"
    page-default-back-link="/"
    show-info="true"
  >
    <ion-text
      color="primary"
      class="ion-text-center title-headline"
    >
      <h1>Kalender</h1>
      <ion-text color="secondary">
        <h3>{{ dayName }} {{ dateString }}</h3>
      </ion-text>
    </ion-text>
    <ion-item>
      <ion-icon
        slot="start"
        :icon="icons.calendar"
      ></ion-icon>
      <ion-label>Dato</ion-label>
      <ion-datetime
        display-format="DD/MM/YYYY"
        picker-format="DD/MMMM/YYYY"
        display-timezone="utc"
        v-model="theDate"
        max="2099-10-31"
        placeholder="Vælg dato"
      ></ion-datetime>
    </ion-item>
    <ion-item
      class="filter-button"
      @click="showFilters = !showFilters"
    >
      <ion-icon
        :icon="icons.filter"
        slot="start"
      ></ion-icon>
      <ion-input readonly>
        Filtre
      </ion-input>
      <ion-icon
        :icon="chevron"
        class="chevron-icon"
      ></ion-icon>

    </ion-item>
    <div
      class="filter-dropdown"
      v-if="showFilters && filters"
    >
      <template
        v-for="(filter, key, index) in filters"
        :key="index"
      >
        <template v-if="filter.name != 'Dato'">
          <ion-item>
            <ion-label :for="filter.name">
              <ion-text>{{ filter.name }}</ion-text>
            </ion-label>
            <ion-checkbox
              :name="filter.name"
              color="primary"
              v-model="filter.value"
            ></ion-checkbox>
          </ion-item>
        </template>
      </template>

      <ion-button
        fill="clear"
        @click="resetFilters"
        class="asLink"
      >
        <ion-icon
          :icon="icons.close"
          slot="start"
          class="reset-icon"
        ></ion-icon>
        Nulstil
      </ion-button>
    </div>
    <ion-list
      v-if="dateString && dateValid"
      class="work-list"
    >
      <template v-for="item in filteredList">
        <template v-if="item[0] != 'Dato'">
          <ion-item
            button="true"
            color="secondary"
            @click="setActive(item[0])"
          >
            <ion-label>{{ item[0] }}</ion-label>
            <ion-icon :icon="item[0] == isActive ? icons.up : icons.down"></ion-icon>
          </ion-item>
          <work-plan-person
            v-if="item[0] == isActive"
            :person="getPerson(item[1])"
          ></work-plan-person>
        </template>
      </template>
    </ion-list>
    <h3 style="text-align: center; margin-top: 20px;" v-else>Ingen kalender for valgte dato</h3>

  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import {
  callSharp,
  chevronDown,
  chevronUp,
  calendarOutline,
  filter,
} from "ionicons/icons";
import WorkPlanPerson from "../components/app/WorkPlanPerson";
import moment from "moment";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonList,
  IonItemDivider,
  IonDatetime,
  IonCheckbox,
  IonInput,
} from "@ionic/vue";

export default {
  name: "Calendar",
  data() {
    return {
      showFilters: false,
      theDate: null,
      dayName: null,
      dateString: null,
      workPlanDay: null,
      days: [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
      ],
      isActive: null,
      icons: {
        phone: callSharp,
        down: chevronDown,
        up: chevronUp,
        calendar: calendarOutline,
        filter: filter,
      },
      filters: null,
      dateValid: false
    };
  },
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonIcon,
    IonText,
    IonList,
    IonDatetime,
    IonItemDivider,
    WorkPlanPerson,
    IonCheckbox,
    IonInput,
  },
  computed: {
    ...mapGetters(["workPlanData", "data"]),
    chevron() {
      return this.showFilters ? this.icons.up : this.icons.down;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filteredList() {
      var app = this;

      var list = this.workPlanDay;

      var isFilterActive = app.filters.some(function (filter) {
        if (filter.value) {
          return true;
        }
        return false;
      });

      if (isFilterActive) {
        list = this.workPlanDay.filter(function (item) {
          for (var key in app.filters) {
            if (app.filters[key].name == item[0]) {
              if (app.filters[key].value) {
                return true;
              }
            }
          }
          return false;
        });
      }
      return list;
    },
  },
  watch: {
    theDate: {
      handler: function (val) {
        if (val) {
          this.dayName = this.days[moment(val).day()];
          this.dateString = moment(val).format("DD/MM/YYYY");
          var data = this.workPlanData.find((x) => x.Dato == this.dateString);
          if (data != null || data != undefined) {
            this.dateValid = true;
            this.workPlanDay = Object.entries(data);
            this.setFilters();
          } else {
            this.dateValid = false;
          }
        }
      },
      immediate: false,
    },
  },
  mounted() {
    this.getDate();
  },
  methods: {
    resetFilters() {
      for (var i in this.filters) {
        this.filters[i].value = false;
      }
    },
    setActive(key) {
      if (this.isActive == key) {
        this.isActive = null;
        return;
      }
      this.isActive = key;
    },
    toggleFilter(key) {
      this.filters[key] = !this.filters[key];
    },
    setFilters() {
      var filters = JSON.parse(JSON.stringify(this.workPlanDay));
      var arrObj = [];

      Object.values(filters).forEach(function (item) {
        arrObj.push({ name: item[0], value: false });
      });

      this.filters = arrObj;
    },
    getDate() {
      var datePlus = moment().format();
      this.theDate = datePlus;
    },
    getPerson(ini) {
      if (ini) {
        var person = this.data.find(
          (x) => x.Initialer.toUpperCase() == ini.toUpperCase()
        );
        return person ? person : null;
      }
      return null;
    },
  },
};
</script>